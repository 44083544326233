import {
  Button,
  Card,
  Input,
  message,
  List,
  Typography,
  Modal,
  Tag,
  Select,
  InputNumber,
  Checkbox,
} from "antd";
import React, { useEffect, useState } from "react";
import Axios from "../../AxiosWrapper";
import { User, UserTag } from "../../types";
import { showError } from "../../utils";
import { UserActions } from "./UserActions";
import { useAuth } from "../../contexts/AuthContext";
const { Title } = Typography;
type Props = {
  user: User;
  onSuccess: () => void;
};
export const UserDetails = (props: Props) => {
  const { user } = props;
  const { auth } = useAuth();
  const renderAddress = () => {
    if (!user.address) return null;
    const {
      address_line_1,
      address_line_2,
      postal_code,
      city,
      country_code,
      state,
    } = user.address;
    return (
      <List.Item style={{ display: "block" }}>
        <b>Address:</b>
        <ul>
          <li>{address_line_1}</li>
          <li>{address_line_2}</li>
          <li>
            {postal_code} {city}
          </li>
          <li>{state}</li>
          <li>{country_code}</li>
        </ul>
      </List.Item>
    );
  };

  return (
    <div>
      <Card>
        <Title level={3}>User Details</Title>
        <List>
          <List.Item>
            <b>Email:</b> {user.email}
            {auth?.role === "admin" && <ChangeEmail user={props.user} />}
          </List.Item>
          <List.Item>
            <b>Company name:</b> {user.company_name}
          </List.Item>
          <List.Item>
            <b>Company website:</b> {user.company_website}
          </List.Item>
          <List.Item>
            <b>VAT number:</b> {user.vat_number}
          </List.Item>
          {renderAddress()}
        </List>

        <UserNote user={user} onUpdateNote={props.onSuccess} />
        <DiscountRates user={user} onChange={props.onSuccess} />
        <br />
        <AddressOverride user={props.user} />
        <br />
      </Card>
      <br />

      <UserActions user={user} />
      <UserTags user={user} />
    </div>
  );
};

const UserNote = (props: { user: User; onUpdateNote: () => void }) => {
  const [note, setNote] = useState("");
  useEffect(() => {
    setNote(props.user.note);
  }, [props.user.note]);
  const updateNote = async () => {
    try {
      await Axios.post(`/api/admin/users/${props.user.id}/note`, { note });
      message.success("Updated user note");
      props.onUpdateNote();
    } catch (error) {
      showError("Failed to update Note", error);
    }
  };
  return (
    <div style={{ display: "flex" }}>
      Note:
      <Input value={note} onChange={(e) => setNote(e.target.value)} />
      {note !== props.user.note && (
        <Button type={"primary"} onClick={updateNote}>
          Update
        </Button>
      )}
    </div>
  );
};

const ChangeEmail = (props: { user: User }) => {
  const [visible, setVisible] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    setVisible(false);
    setNewEmail("");
  };
  const submitNewEmail = async () => {
    try {
      setIsLoading(true);
      await Axios.put(`/api/admin/users/${props.user.id}/email`, {
        email: newEmail,
      });
      message.success("Updated user email");

      window.location.reload();
    } catch (error) {
      showError("Failed to update email", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>Change Email</Button>
      <Modal
        visible={visible}
        onCancel={close}
        title="Change Email"
        footer={null}
      >
        New Email Address: <br />
        <Input onChange={(e) => setNewEmail(e.target.value)} value={newEmail} />
        <Button onClick={close}>Cancel</Button>
        <Button
          type="primary"
          onClick={submitNewEmail}
          disabled={isLoading}
          loading={isLoading}
        >
          Change Email
        </Button>
      </Modal>
    </>
  );
};

const UserTags = (props: { user: User }) => {
  const [availableTags, setAvailableTags] = useState<UserTag[]>([]);
  const [tags, setTags] = useState<UserTag[]>([]);

  useEffect(() => {
    const getAvailableTags = async () => {
      try {
        const res = await Axios.get(`/api/admin/user_tags`);
        setAvailableTags(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    getAvailableTags();
    refreshTags();
  }, []);
  const refreshTags = async () => {
    try {
      const res = await Axios.get(`/api/admin/users/${props.user.id}/tags`);
      setTags(res.data);
    } catch (error) {
      console.error(error);
    }
  };
  const onChange = async (value: string[]) => {
    try {
      await Axios.put(`/api/admin/users/${props.user.id}/tags`, {
        tags: value,
      });
      refreshTags();
    } catch (error) {
      console.error(error);
    }
  };
  const deleteTag = async (tagId: number) => {
    try {
      await Axios.delete(`/api/admin/users/${props.user.id}/tags/${tagId}`, {});
      refreshTags();
    } catch (error) {
      console.error(error);
    }
  };
  const renderTag = (x: any) => {
    const tag = availableTags.find((tag) => tag.tag === x.value);
    if (!tag) return x.value;
    return (
      <Tag
        color={tag.color}
        key={tag.tag}
        closable
        onClose={() => {
          deleteTag(tag.id);
        }}
      >
        {tag.tag}
      </Tag>
    );
  };
  return (
    <div>
      <Title level={3}>Tags</Title>
      <Select
        mode="tags"
        style={{ width: 1000 }}
        placeholder="Please select"
        value={tags.map((tag) => tag.tag)}
        onChange={onChange}
        tagRender={renderTag}
        options={availableTags.map((tag) => ({
          label: tag.tag,
          value: tag.tag,
        }))}
      />
    </div>
  );
};
type DiscountRatesProps = {
  user: User;
  onChange: () => void;
};
const DiscountRates = (props: DiscountRatesProps) => {
  const onChange = async (value: number | null, key: string) => {
    try {
      const newRates = {
        ...props.user.discount_rates,
        [key]: value?.toString(),
      };
      await Axios.put(`/api/admin/users/${props.user.id}/discount_rates`, {
        discount_rates: newRates,
      });
      message.success("Updated discount rates");
      props.onChange();
    } catch (error) {
      showError("Failed to update discount rates", error);
    }
  };

  if (!props.user) return null;
  return (
    <div>
      <h3>Discount Rates</h3>
      <div>
        {[250, 500, 1000].map((x) => (
          <div key={x}>
            {x} credits:{" "}
            <InputNumber
              style={{ width: 100 }}
              size="small"
              addonAfter="%"
              value={+(props.user.discount_rates?.[x] || 0)}
              onChange={(value) => onChange(value, x.toString())}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const AddressOverride = (props: { user: User }) => {
  const [showModal, setShowModal] = useState(false);
  const [override, setOverride] = useState<string>("");

  useEffect(() => {
    if (showModal) fetchCurrentOverride();
  }, [showModal]);
  const fetchCurrentOverride = async () => {
    try {
      const res = await Axios.get(
        `/api/admin/users/${props.user.id}/override_address`
      );
      if (!res.data) return;
      const { latitude_override, longitude_override } = res.data;
      setOverride(`${latitude_override},${longitude_override}`);
    } catch (error) {
      console.error(error);
    }
  };
  const submitNewOverride = async () => {
    try {
      await Axios.put(`/api/admin/users/${props.user.id}/override_address`, {
        latitude_override: parseFloat(override.split(",")[0]),
        longitude_override: parseFloat(override.split(",")[1]),
      });
      setShowModal(false);
      message.success("Updated address override");
    } catch (error) {
      console.error(error);
      showError("Failed to update address override", error);
    }
  };

  return (
    <div>
      <Button onClick={() => setShowModal(true)}>Override Address</Button>
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        onOk={submitNewOverride}
      >
        <Title level={3}>Address Override</Title>
        <Input onChange={(e) => setOverride(e.target.value)} value={override} />
      </Modal>
    </div>
  );
};
